// @ts-ignore
import React, { useEffect } from "react";
import { PlasmicComponent, PlasmicRootProvider } from '@plasmicapp/loader-gatsby';
import { Script, graphql } from 'gatsby';

// You can use any library you want for <head/> injection
import { Helmet } from 'react-helmet';
import { initPlasmicLoaderWithRegistrations } from '../plasmic-init';
import './styles.scss';

export const query = graphql`
  query ($path: String) {
    plasmicComponents(componentNames: [$path])
    plasmicOptions
  }
`;

const PlasmicGatsbyPage = ({ location, data }) => {
  const { plasmicComponents, plasmicOptions } = data;
  const pageMeta = plasmicComponents.entryCompMetas[0];
  const pageMetadata = pageMeta.pageMetadata;

  useEffect(() => {
    document.querySelectorAll('a').forEach(link => {
      if (location.search.length) {
        link.setAttribute('href', `${link.href}${location.search}`)
      } else {
        link.setAttribute('href', `${link.href}?first_referring_website=${document.referrer}`)
      }
    });
  }, []);

  const statCounterScript = `<!-- Default Statcounter code for BlueTape https://www.bluetape.com -->
    <script type="text/javascript">
      var sc_project=12774569;
      var sc_invisible=1;
      var sc_security="b13f325d";
    </script>
    <noscript>
      <div class="statcounter">
        <a title="Web Analytics" href="https://statcounter.com/" target="_blank">
          <img class="statcounter" src="https://c.statcounter.com/12774569/0/b13f325d/1/" alt="Web Analytics" referrerPolicy="no-referrer-when-downgrade">
        </a>
      </div>
    </noscript>
  <!-- End of Statcounter Code -->`;

  return (
    <>
      <PlasmicRootProvider
        loader={initPlasmicLoaderWithRegistrations(plasmicOptions)}
        prefetchedData={plasmicComponents}
        globalVariants={[{ name: 'locale', value: 'en-US' }]}>
        <Helmet>
          {pageMetadata.title && <title>{pageMetadata.title}</title>}
          {pageMetadata.title && <meta property="og:title" content={pageMetadata.title} />}
          {pageMetadata.title && <meta name="title" content={pageMetadata.title} />}
          {pageMetadata.description && <meta property="og:description" content={pageMetadata.description} />}
          {pageMetadata.openGraphImageUrl && <meta property="og:image" content={pageMetadata.openGraphImageUrl} />}
          {pageMetadata.description && <meta name="description" content={pageMetadata.description} />}
          <link href="https://fonts.googleapis.com/css?family=Roboto:400,500,600,700&display=swap" rel="stylesheet"/>
          <link rel="preconnect" href="https://fonts.googleapis.com"/>
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
          <link rel="apple-touch-icon" href="https://cdn-dafdp.nitrocdn.com/KdHMQbfXkvrmcGengglUTIUQNykzKIuh/assets/static/optimized/rev-72c1acb/wp-content/uploads/2022/07/cropped-white-180x180.png"></link>
          <link rel="icon" sizes="192x192" href="https://cdn-dafdp.nitrocdn.com/KdHMQbfXkvrmcGengglUTIUQNykzKIuh/assets/static/optimized/rev-72c1acb/wp-content/uploads/2022/07/cropped-white-192x192.png"></link>
          <link rel="icon" sizes="32x32" href="https://cdn-dafdp.nitrocdn.com/KdHMQbfXkvrmcGengglUTIUQNykzKIuh/assets/static/optimized/rev-72c1acb/wp-content/uploads/2022/07/cropped-white-32x32.png"></link>
          <link rel="prefetch" type="text/html" href="https://app.bluetape.com/signup"></link>
        </Helmet>
        <PlasmicComponent component={pageMeta.displayName} />
      </PlasmicRootProvider>
      <Script src="https://js.hs-scripts.com/8561714.js" strategy="idle"/>
      <Script type="text/javascript">
        var sc_project=12774569;
        var sc_invisible=1;
        var sc_security="b13f325d";
      </Script>
      <noscript>
        <div className="statcounter">
          <a title="Web Analytics" href="https://statcounter.com/" target="_blank">
            <img className="statcounter" src="https://c.statcounter.com/12774569/0/b13f325d/1/" alt="Web Analytics" referrerPolicy="no-referrer-when-downgrade"/>
          </a>
        </div>
      </noscript>
      <Script src="https://www.statcounter.com/counter/counter.js" strategy="idle"/>
    </>
  );
};

export default PlasmicGatsbyPage;
